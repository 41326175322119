/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube} from '../../components';
import './home.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button } from "react-bootstrap";

const colorMorado = "#7f2e93"
const colorDorado = "#af9b59"

function Home() {
    const location = useLocation();
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState(0);
    const [TotalRepartir, setTotalRepartir] = useState(0.00)
    const [BaseTotalRepartir, setBaseTotalRepartir] = useState(1.00)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0.00)
    const [PremiosBig, setPremiosBig] = useState(0.00)




    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0.00)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1); 
    const [precioCarton, setPrecioCarton] = useState(1.00);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false);
    const [adminStatus, setAdminStatus] = useState(false);

    //ESPECIAL
    const [PremiosLittleEspecial, setPremiosLittleEspecial] = useState(0.00)
    const [PremiosBigEspecial, setPremiosBigEspecial] = useState(0.00)
    const [rondasEspecial, setRondasEspecial] = useState(1);
    const [publicidadEspecial, setPublicidadEspecial] = useState(0.00)
    const [sorteoEspecial, setSorteoEspecial] = useState();
    const [vendidosEspecial,setVendidosEspecia] = useState(0);
    const [TotalRepartirEspecial, setTotalRepartirEspecial] = useState(0.00)
    const [fechaEspecial, setFechaEspecial] = useState();
    
    const [tipoSorteo, setTipoSorteo] = useState("");
    const [linkCartonesNormal, setLinkCartonesNormal] = useState("");
    const [linkCartonesEspecial, setLinkCartonesEspecial] = useState(""); 
    const [porcentajePromotor, setPorcentajePromotor] = useState(0.10)
    const [porcentajeAdmin, setPorcentajeAdmin] = useState(0.05)
    const [bonusPromotor, setBonusPromotor] = useState(0.05)

    const [telegramLink, setTelegramLink] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [whatsappList, setWhatsappList] = useState([]);
    const [whatsappMode, setWhatsappMode] = useState('');


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
    
    const GetAllConfigs = async () =>{
       await  api.get('/read/configs')
        .then((response) =>{ 
            if(response?.data?.content.length > 0){
                const TempConfigs = response?.data?.content
                setConfigs(TempConfigs);
                TempConfigs.map((item)=>{               
                    const {id,tipo,valor,extra} = item     
                    switch(tipo){ 
                        case 'sorteo_actual':  
                            setSorteoActual(valor) 
                            break;
                        case 'sorteo_especial':  
                            setSorteoEspecial(valor) 
                            break;
                        case 'sorteo_youtube':  
                            setLink(valor)
                            break; 
                        case 'sorteo_fecha':  
                            setFecha(valor)
                            break;
                        case 'sorteo_fecha_especial':  
                            setFechaEspecial(valor)
                            break;
                        case 'sorteo_hora':  
                            setHora(valor)
                            break;
                        case 'sorteo_dia':  
                            setDia(valor)
                            break;
                        case 'ventas_status':  
                            setVentasStatus(valor=='1')
                            break;
                        case 'promotores_status':  
                            setPromotorStatus(valor=='1')
                            break;
                        case 'administradores_status':  
                            setAdminStatus(valor=='1')
                            break;
                        case 'sorteo_rondas':  
                            setRondas(valor)
                            break;
                        case 'sorteo_rondas_especial':  
                            setRondasEspecial(valor)
                            break;
                        case 'premio_base_small':  
                            setPremiosLittle(parseFloat(valor))
                            break;
                        case 'premio_base_big':  
                            setPremiosBig(parseFloat(valor))
                            break;
                        case 'premio_base_total':  
                            setTotalRepartir(parseFloat(valor))
                            break; 
                        case 'premio_base_small_especial':  
                            setPremiosLittleEspecial(parseFloat(valor))
                            break;
                        case 'premio_base_big_especial':  
                            setPremiosBigEspecial(parseFloat(valor))
                            break;
                        case 'premio_base_total_especial':  
                            setTotalRepartirEspecial(parseFloat(valor))
                            break; 
                        case 'cartones_precio':  
                            setPrecioCarton(parseFloat(valor));
                            break;
                        case 'promotores_empleo':  
                            setPromotorEmpleo(valor=='1')
                            break; 
                        case 'video_home_1':  
                            setVideoHome1(item)
                            break; 
                        case 'video_home_2':  
                            setVideoHome2(item)
                            break; 
                        case 'video_ultimo_sorteo':  
                            setVideoUltimo(item)
                            break;
                        case 'cartones_vendidos':  
                            setVendidos(parseInt(valor))
                            break;
                        case 'precio_publicidad':  
                            setPublicidad(parseInt(valor))
                            break;
                        case 'home_historial':  
                            setHistorial(valor=='1')
                            break;
                        case 'tipo_sorteo_activo':  
                             setTipoSorteo(valor)
                             break;
                        case 'link_cartones_normal':  
                            setLinkCartonesNormal(valor)
                            break;
                        case 'link_cartones_especial':  
                            setLinkCartonesEspecial(valor)
                            break;
                        case 'link_telegram':  
                            setTelegramLink(valor)
                            break;
                        case 'link_whatsapp':  
                            setWhatsappLink(valor)
                            break;
                        case 'list_whatsapp':  
                            setWhatsappList(valor.split(','))
                            break;
                        case 'link_whatsapp_mode':  
                            setWhatsappMode(valor)
                            break;
                            //cartones_vendidos
                        default :  break;
                    }  


                })
            } 
         })
      .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
      });
      //updateTotalRepartir()
    }
    useEffect( () => {
       updateTotalRepartir() 
    }, [vendidos,PremiosBig,PremiosLittle,rondas]);
    

    const updateTotalRepartir = async() =>{
        const pagoPromotores = 1 - porcentajePromotor - porcentajeAdmin - bonusPromotor
        const totalVendidosUsd = vendidos * precioCarton
        const totalDisponible =  totalVendidosUsd  
        let totalRecolectado = (totalVendidosUsd  * pagoPromotores ) - publicidad

       
        //ssetRondas(1) 
        const iniciaRonda = totalRecolectado <= TotalRepartir
        const unaRonda = totalRecolectado <= (BaseTotalRepartir * 2)
        const dosRonda = totalRecolectado > (BaseTotalRepartir * 2) && totalRecolectado <= (BaseTotalRepartir * 3)
        const tresRonda = totalRecolectado > (BaseTotalRepartir * 3)
        
        if(dosRonda){
            setRondas(2) 
            if(totalRecolectado > TotalRepartir){ 
                const premioS = totalRecolectado / (rondas * 8)
                setPremiosLittle(premioS ) 
                setPremiosBig((premioS) * 2 ) 
                setTotalRepartir(totalRecolectado)
            }
            console.log("dosRonda")
        }
        if(tresRonda){
            setRondas(3) 
            if(totalRecolectado > TotalRepartir){
                const premioS = totalRecolectado / (rondas * 8)
                setPremiosLittle(premioS ) 
                setPremiosBig((premioS) * 2 )  
                setTotalRepartir(totalRecolectado)
            }
            console.log("tresRonda")
        }

        if(iniciaRonda){ 
            
            console.log("iniciaRonda")
        }
        if(unaRonda){
            if(!iniciaRonda){
            //    setTotalRepartir(totalRecolectado)
                console.log("unaRonda > iniciaRonda")
            }
            console.log("unaRonda")
        }
        
        console.log("total Recolectado",totalRecolectado)
        console.log("total a repartir", TotalRepartir)
    }


    function formatearFecha(fecha) {
        const date = new Date(fecha);
        const dia = date.getDate();
        const mes = date.getMonth() + 1; // Los meses van de 0 a 11
        const año = date.getFullYear() % 100; // Obtiene los últimos dos dígitos del año
      
        // Agregar un cero delante si el día o el mes tienen un solo dígito
        const diaFormateado = dia < 10 ? `0${dia}` : dia;
        const mesFormateado = mes < 10 ? `0${mes}` : mes;
      
        return `${diaFormateado}/${mesFormateado}/${año}`;
    }

    useEffect( () => {
        GetAllConfigs()  
        const timeout = setTimeout(() => {
            updateTotalRepartir()
        }, 10000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect( () => {
       // updateTotalRepartir() 
    }, [vendidos,TotalRepartir]);

    const Premio = ({name = "Esquinas",premio = 0,size = "40",solid}) =>{
        
        const general = 'w-full flex items-center justify-center rounded-[90px]'
        const BgGradient = `bg-[linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)]`
        const BgSolid = ` bg-[#7f2e93aa] lg:bg-[#7f2e93] `
        const Small = {
            general:'h-[40px] lg:h-[50px] text-[15px] ' + general,
            title:'text-[white] text-[12px] lg:text-[15px] mt-2 lg:-mt-0',
            premio:`text-white lg:text-[#ffcc00] text-[20px] lg:text-[30px] lg:-mt-2`,
            caja : "text-center flex flex-col lg:pt-[5px] lg:pb-[5px]  lg:-mt-2 h-[60px] lg:h-[70px] "
        }
        const Medium = {
            general:'w-[70%] lg:w-[50%] h-[70px] lg:h-[80px] text-[25px] -mt-5 lg:-mt-0 ' + general,
            title:'text-[white]  text-[20px] lg:text-[25px]',
            premio:'text-white lg:text-[#ffcc00] text-[40px]  lg:text-[60px] -mt-4',
            caja : "text-center flex flex-col pt-[5px] pb-[5px] -mt-2 lg:-mt-6 lg:gap-2"
        }
        const Large = {
            general:'h-[180px] text-[90px] ' + general,
            title:'text-[white] text-[90px]',
            premio:'text-[#ffcc00] text-[180px] ',
            caja : "text-center flex flex-col pt-[5px] pb-[5px] "
        }
        let Css_01 = general
        switch(size){
            case "small" : Css_01 = Small; break ;
            case "medium" : Css_01 = Medium; break ;
            case "large" : Css_01 = Large; break ;
            default : break ;
        }

        const bg_gradient = solid ? BgSolid : BgGradient
        return (<>
            <div className={Css_01?.general + bg_gradient}>
                <span className={Css_01?.caja} >
                    <b className={Css_01?.title}>{name}</b>
                    <b className={Css_01?.premio} style={{}}>
                        $ {premio?.toFixed(2)}
                         
                    </b>
                </span>
            </div>
        </>)
    }
    const Premio2 = ({name = "Esquinas",premio = 0,size = "40",solid}) =>{  
        return (<>
            <div className={"w-6/12 left"}>
                <span className={"flex gap-1 text-white text-sm "} >
                    <span className={""}>{name}</span>
                    <b className={"text-yellow-500 font-bold "}> {premio?.toFixed(2)}$   </b>
                </span>
            </div>
        </>)
    }
    const Premio3 = ({name = "Total a Repartir",premio = 0}) =>{  
        return (<>
            <div className={"w-8/12 left"}>
                <span className={"flex flex-col gap-0 text-white -mt-2 "} >
                    <b className={"text-xl"}>{name}</b>
                    <b className={"text-yellow-500 font-bold text-5xl"}> $ {premio?.toFixed(2)}   </b>
                    <b className={"text-xl"}>{"POR RONDA"}</b>
                </span>
            </div>
        </>)
    }
    const Header_1 = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:"url('images/bg_head_1.png')",
                    height:height,
                    padding:isMobile?10: 20,
                    backgroundSize: "cover"
                }}>
                <div 
                    className="flex flex-col lg:flex-row"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                    {   isMobile &&
                        <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                        </div>
                    }


                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                    
                        { !isMobile &&
                            <div className="flex flex-col h-[180px] "
                                style={
                                {
                                    background:"url('images/logo_bingove_01.png')",
                                    backgroundSize:isMobile?"300px auto": "375px 180px",
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"left"
                                }}>
                            </div>
                        }
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>
                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-2 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[14px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>{rondas} RONDA{rondas>1?'S':''}</b>
                                </span>
                            </div>
                        </div>
                        {
                            isMobile && 
                            <div className="flex mr-[10px] p-[10px]    flex-col justify-center items-center  bg-[#ff392a] h-[100px] w-6/12  rounded-[20px] -mt-[5px] gap-y-[10px] " > 

                                <div className="flex justify-evently w-full mt-[30px] w-full" >
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                                </div>
                                <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                                <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-full lg:w-[375px] text-[white]" >
                                    <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            HORARIO
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] mt-[2px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{hora} </b>      
                                        </span>
                                    </div>
                                    <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            {dia}
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{fecha} </b>      
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="bg-[#55555500] h-[100%] w-full lg:w-[45%] flex flex-col items-center"
                        >
                        {   !isMobile &&
                            <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                                <h2 style={{color:"white"}}>Sorteo Nº</h2>
                                <div style={
                                    {
                                        background:"#fff",
                                        height:"40px",
                                        width:"auto",
                                        display:"flex",
                                        alignItems:"center",
                                        padding:10,
                                        paddingLeft:50,
                                        paddingRight:50,
                                        borderRadius:10
                                    }}>
                                    <span style={{fontSize:24,textAlign:"center"}}>
                                        <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                        <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                                    </span>
                                </div>
                            </div>
                        }
                        <div className="bg-[#9999900] h-[130px] max-[480px]:mt-[0px]  mt-[100px]  lg:h-[100px] w-[100%] flex items-end">
                            <div  className="h-[120px] lg:h-[180px] flex items-center w-[100%] justify-center rounded-[90px]"
                                style={
                                {
                                    background:"linear-gradient(0deg,#00000000 0%,#7f2e93 20%,#7f2e93 100%)"
                                }}>
                                <span className="text-center flex flex-col">
                                    <b className="text-[white] text-[24px] lg:text-[40px]">Total a repartir</b>
                                    <b className="text-[#ffcc00] text-[50px] lg:text-[80px] -mt-[20px]">$ {TotalRepartir?.toFixed(2)}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    {   !isMobile &&
                        <div className="bg-[#ff392a] h-[150px] w-[400px] rounded-[20px]" > 
                            <div className="flex justify-evently w-full pt-3 w-full" >
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                            </div>
                            <span className="text-white text-[48px] font-bold w-full">@bingo_ve</span>
                            <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-[auto] lg:w-[375px] text-[white] mt-3" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        HORARIO
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] mt-[10px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{hora} </b>      
                                    </span>
                                </div>
                                <div 
                                    className="flex flex-col z-[9] items-center lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        {dia}
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{fecha} </b>      
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col lg:flex-row h-[100%] w-[100%] gap-[20px] justify-between items-center">
                    {   !isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                    <div className="flex max-sm:mt-5 flex-col gap-[15px] w-full">
                        <div className="flex flex-row gap-[5px] w-full">
                            <Premio size="small" name="Esquinas" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Diagonal" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Diagonal Invetido" premio={PremiosLittle } solid/>
                        </div>
                        <div className="flex flex-row gap-[5px] w-full  mb-[20px] lg:mb-[0px]">
                            <Premio size="small" name="Cruz" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Linea Horizontal" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Linea Vertical" premio={PremiosLittle } solid/>
                        </div>
                    </div>
                    <Premio size="medium" name="Carton Lleno" premio={PremiosBig } solid/>
                    {   isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                </div>
            </div>
        </>)
    }
    const Header_2 = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:"url('images/especial/fondo-dorado-bingo-min-black.png ')",
                    height:height,
                    padding:isMobile?10: 20,
                    backgroundSize: "cover"
                }}>
                <div 
                    className="flex flex-col lg:flex-row"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                    {   isMobile &&
                        <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº:  </b>
                                <b style={{color:"red"}}>{sorteoEspecial?.slice(0, 5)}</b>
                                <b>{sorteoEspecial?.slice(5, sorteoEspecial.length)}</b>
                            </span>
                        </div>
                        </div>
                    }


                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                    
                        { !isMobile &&
                            <div className="flex flex-col h-[180px] "
                                style={
                                {
                                    background:"url('images/logo_bingove_01.png')",
                                    backgroundSize:isMobile?"300px auto": "375px 180px",
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"left"
                                }}>
                            </div>
                        }
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:'skewX(30deg)'}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{3}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>
                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-2 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[14px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>{rondasEspecial} RONDA{rondasEspecial>1?'S':''}</b>
                                </span>
                            </div>
                        </div>
                        {
                            isMobile && 
                            <div className="flex mr-[10px] p-[10px]    flex-col justify-center items-center  bg-[#ff392a] h-[100px] w-6/12  rounded-[20px] -mt-[5px] gap-y-[10px] " > 

                                <div className="flex justify-evently w-full mt-[30px] w-full" >
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                                </div>
                                <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                                <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-full lg:w-[375px] text-[white]" >
                                    <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            HORARIO
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] mt-[2px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{hora} </b>      
                                        </span>
                                    </div>
                                    <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            {dia}
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{fechaEspecial} </b>      
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="bg-[#55555500] h-[100%] w-full lg:w-[45%] flex flex-col items-center"
                        >
                        {   !isMobile &&
                            <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                                <h2 style={{color:"white"}}>Sorteo Nº</h2>
                                <div style={
                                    {
                                        background:"#fff",
                                        height:"40px",
                                        width:"auto",
                                        display:"flex",
                                        alignItems:"center",
                                        padding:10,
                                        paddingLeft:50,
                                        paddingRight:50,
                                        borderRadius:10
                                    }}>
                                    <span style={{fontSize:24,textAlign:"center"}}>
                                        <b style={{color:"red"}}>{sorteoEspecial?.slice(0, 5)}</b>
                                        <b>{sorteoEspecial?.slice(5, sorteoEspecial.length)}</b>
                                    </span>
                                </div>
                            </div>
                        }
                        <div className="bg-[#9999900] h-[130px] max-[480px]:mt-[0px]  mt-[100px]  lg:h-[100px] w-[100%] flex items-end">
                            <div  className="h-[120px] lg:h-[180px] flex items-center w-[100%] justify-center rounded-[90px]"
                                style={
                                {
                                    background:"linear-gradient(0deg,#00000000 0%,#7f2e93 20%,#7f2e93 100%)"
                                }}>
                                <span className="text-center flex flex-col">
                                    <b className="text-[white] text-[24px] lg:text-[40px]">Especial Madres</b>
                                    <b className="text-[#ffcc00] text-[50px] lg:text-[80px] -mt-[20px]">$ {TotalRepartirEspecial?.toFixed(2)}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    {   !isMobile &&
                        <div className="bg-[#ff392a] h-[150px] w-[400px] rounded-[20px]" > 
                            <div className="flex justify-evently w-full pt-3 w-full" >
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                            </div>
                            <span className="text-white text-[48px] font-bold w-full">@bingo_ve</span>
                            <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-[auto] lg:w-[375px] text-[white] mt-3" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        HORARIO
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] mt-[10px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{hora} </b>      
                                    </span>
                                </div>
                                <div 
                                    className="flex flex-col z-[9] items-center lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        {dia}
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{fechaEspecial}</b>      
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col lg:flex-row h-[100%] w-[100%] gap-[20px] justify-between items-center">
                    {   !isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                    <div className="flex max-sm:mt-5 flex-col gap-[15px] w-full">
                        <div className="flex flex-row gap-[5px] w-full">
                            <Premio size="small" name="Esquinas" premio={PremiosLittleEspecial } solid/>
                            <Premio size="small" name="Diagonal" premio={PremiosLittleEspecial } solid/>
                            <Premio size="small" name="Diagonal Invetido" premio={PremiosLittleEspecial } solid/>
                        </div>
                        <div className="flex flex-row gap-[5px] w-full  mb-[20px] lg:mb-[0px]">
                            <Premio size="small" name="Cruz" premio={PremiosLittleEspecial } solid/>
                            <Premio size="small" name="Linea Horizontal" premio={PremiosLittleEspecial } solid/>
                            <Premio size="small" name="Linea Vertical" premio={PremiosLittleEspecial } solid/>
                        </div>
                    </div>
                    <Premio size="medium" name="Carton Lleno" premio={PremiosBigEspecial } solid/>
                    {   isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                </div>
            </div>
        </>)
    }
    const Header_Especial = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:isMobile?"url('images/especial/fondo-especial-mobile-4.png')":"url('images/especial/fondo-especial-2.png')",
                    height:height,
                    padding:isMobile?10: 20,
                    backgroundSize: "cover"
                }}>
                <div 
                    className="flex flex-col lg:flex-row"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                    {   isMobile &&
                        <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                        </div>
                    }


                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                    
                        { !isMobile &&
                            <div className="flex flex-col h-[180px] "
                                style={
                                {
                                    background:"url('images/especial/logo-bingove-especial.png')",
                                    backgroundSize:isMobile?"300px auto": "375px 180px",
                                    backgroundRepeat:"no-repeat",
                                    backgroundPosition:"left"
                                }}>
                            </div>
                        }
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>
                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-2 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[14px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>{rondas} RONDA{rondas>1?'S':''}</b>
                                </span>
                            </div>
                        </div>
                        {
                            isMobile && 
                            <div className="flex mr-[10px] p-[10px]    flex-col justify-center items-center  bg-[#ff392a] h-[100px] w-6/12  rounded-[20px] -mt-[5px] gap-y-[10px] " > 

                                <div className="flex justify-evently w-full mt-[30px] w-full" >
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                    <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                                </div>
                                <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                                <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-full lg:w-[375px] text-[white]" >
                                    <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            HORARIO
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] mt-[2px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{hora} </b>      
                                        </span>
                                    </div>
                                    <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                        style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                        <span className='text-[12px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                            style={{transform:"skewX(30deg)"}}>
                                            {dia}
                                        </span>
                                        <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                                <b >{fecha} </b>      
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="bg-[#55555500] h-[100%] w-full lg:w-[45%] flex flex-col items-center"
                        >
                        {   !isMobile &&
                            <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                                <h2 style={{color:"white"}}>Sorteo Nº</h2>
                                <div style={
                                    {
                                        background:"#fff",
                                        height:"40px",
                                        width:"auto",
                                        display:"flex",
                                        alignItems:"center",
                                        padding:10,
                                        paddingLeft:50,
                                        paddingRight:50,
                                        borderRadius:10
                                    }}>
                                    <span style={{fontSize:24,textAlign:"center"}}>
                                        <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                        <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                                    </span>
                                </div>
                            </div>
                        }
                        <div className="bg-[#9999900] h-[130px] max-[480px]:mt-[0px]  mt-[100px]  lg:h-[100px] w-[100%] flex items-end">
                            <div  className="h-[120px] lg:h-[180px] flex items-center w-[100%] justify-center rounded-[90px]"
                                style={
                                {
                                    background:"linear-gradient(0deg,#00000000 0%,#7f2e93 20%,#7f2e93 100%)"
                                }}>
                                <span className="text-center flex flex-col">
                                    <b className="text-[white] text-[24px] lg:text-[40px]">Total a repartir</b>
                                    <b className="text-[#ffcc00] text-[50px] lg:text-[80px] -mt-[20px]">$ {TotalRepartir?.toFixed(2)}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    {   !isMobile &&
                        <div className="bg-[#ff392a] h-[150px] w-[400px] rounded-[20px]" > 
                            <div className="flex justify-evently w-full pt-3 w-full" >
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                <img className="object-contain h-[20px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                            </div>
                            <span className="text-white text-[48px] font-bold w-full">@bingo_ve</span>
                            <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-[auto] lg:w-[375px] text-[white] mt-3" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        HORARIO
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] mt-[10px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{hora} </b>      
                                    </span>
                                </div>
                                <div 
                                    className="flex flex-col z-[9] items-center lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[14px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        {dia}
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                        <b >{fecha} </b>      
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col lg:flex-row h-[100%] w-[100%] gap-[20px] justify-between items-center">
                    {   !isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                    <div className="flex max-sm:mt-5 flex-col gap-[15px] w-full">
                        <div className="flex flex-row gap-[5px] w-full">
                            <Premio size="small" name="Esquinas" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Diagonal" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Diagonal Invetido" premio={PremiosLittle } solid/>
                        </div>
                        <div className="flex flex-row gap-[5px] w-full  mb-[20px] lg:mb-[0px]">
                            <Premio size="small" name="Cruz" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Linea Horizontal" premio={PremiosLittle } solid/>
                            <Premio size="small" name="Linea Vertical" premio={PremiosLittle } solid/>
                        </div>
                    </div>
                    <Premio size="medium" name="Carton Lleno" premio={PremiosBig } solid/>
                    {   isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                </div>
            </div>
        </>)
    }

    const Header_EspecialMadres = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:isMobile?"url('images/especial/fondo-especial-mobile-4.png')":"url('images/especial/fondo-especial-2.png')",
                    height:height,
                    padding:isMobile?10: 20,
                    backgroundSize: "cover"
                }}>
                <div  className="flex flex-col lg:flex-row pt-2"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                    {   !isMobile &&
                        <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                        </div>
                    } 
                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                        
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                             
                            <img  className="w-[500px] h-[500px]" src="images/especial/logo-bingove-especial.png" alt="logo bingo ve especial"/>
                            <br />
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>

                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-4 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[12px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>+7 premios</b>
                                </span>
                            </div>
                        </div> 
                        <div className="flex mr-[10px] p-[5px] flex-col justify-start items-center  bg-[#ff392a] h-[70px] w-6/12  rounded-[20px]  gap-y-[10px] " > 

                            <div className="flex flex-col justify-center w-full  w-full" > 
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                                <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                            </div>
                            <div  className="flex flex-row bg-[#00000000] h-[40px] lg:h-[60px] w-full lg:w-[375px] text-[white]" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[100px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}
                                > 
                                    <span className="text-[18px] lg:text-[28px] mt-[2px]" style={{transform:"skewX(30deg)"}}> <b >{hora} </b>  </span>
                                </div>
                                <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[5px] bg-[#8a35a5] w-[100px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}> 
                                    <span className="text-[18px] lg:text-[28px] -mt-[20px] lg:mt-[10px]" style={{transform:"skewX(30deg)"}}> <b >{dia} </b> </span>
                                    <span className="text-[18px] lg:text-[28px] -mt-[10px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}> <b >{fecha} </b> </span>
                                </div>
                            </div>
                        </div> 
                </div>
                </div> 
                <div className="flex flex-col items-end w-full mt-4">
                    <Premio2 size="small" name="Esquinas" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Diagonal" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Diagonal Invetido" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Carton Lleno" premio={PremiosBig }/>
                    <Premio2 size="small" name="Cruz" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Linea Horizontal" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Linea Vertical" premio={PremiosLittle }/>
                </div>  
                <br />
                <div className="flex flex-col items-start w-full mt-40 pt-2 ">
                    <Premio3 name="A REPARTIR" premio={TotalRepartir }/>
                </div> 
            </div>
        </>)
    } 
    const Header_EspecialPadres = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:isMobile?"url('images/especial/fondo-especial-mobile-4.png')":"url('images/especial/fondo-especial-2.png')",
                    height:height,
                    padding:isMobile?10: 20,
                    backgroundSize: "cover"
                }}>
                <div  className="flex flex-col lg:flex-row pt-2"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                    {   !isMobile &&
                        <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                        </div>
                    } 
                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                        
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                             
                            <img  className="w-[500px] h-[500px]" src="images/especial/logo-bingove-especial.png" alt="logo bingo ve especial"/>
                            <br />
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>

                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-4 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[12px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>+7 premios</b>
                                </span>
                            </div>
                        </div> 
                        <div className="flex mr-[10px] p-[5px] flex-col justify-start items-center  bg-[#ff392a] h-[70px] w-6/12  rounded-[20px]  gap-y-[10px] " > 

                            <div className="flex flex-col justify-center w-full  w-full" > 
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                                <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                            </div>
                            <div  className="flex flex-row bg-[#00000000] h-[40px] lg:h-[60px] w-full lg:w-[375px] text-[white]" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[100px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}
                                > 
                                    <span className="text-[18px] lg:text-[28px] mt-[2px]" style={{transform:"skewX(30deg)"}}> <b >{hora} </b>  </span>
                                </div>
                                <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[5px] bg-[#8a35a5] w-[100px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}> 
                                    <span className="text-[18px] lg:text-[28px] -mt-[20px] lg:mt-[10px]" style={{transform:"skewX(30deg)"}}> <b >{dia} </b> </span>
                                    <span className="text-[18px] lg:text-[28px] -mt-[10px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}> <b >{fecha} </b> </span>
                                </div>
                            </div>
                        </div> 
                </div>
                </div> 
                <div className="flex flex-col items-end w-full mt-4">
                    <Premio2 size="small" name="Esquinas" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Diagonal" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Diagonal Invetido" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Carton Lleno" premio={PremiosBig }/>
                    <Premio2 size="small" name="Cruz" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Linea Horizontal" premio={PremiosLittle }/>
                    <Premio2 size="small" name="Linea Vertical" premio={PremiosLittle }/>
                </div>  
                <br />
                <div className="flex flex-col items-start w-full mt-40 pt-2 ">
                    <Premio3 name="A REPARTIR" premio={TotalRepartir }/>
                </div> 
            </div>
        </>)
    } 
    const CarrouselJs = (props) =>{
        const MobileHeight = isMobile? 400 : 550
        const MobileWidth = isMobile? 550 : 550
        return (<>
            <Carousel
                autoPlay={false}
                infiniteLoop={true}
                interval={5000}
                showArrows={true}
                dynamicHeight={false}
                stopOnHover={false}
                showThumbs={false}
                showPaid={false} 
                swipeable={false}                
                className="min-h-[400px] lg:min-h-[400px]  h-[600px] lg:h-[600px] mt-10 lg:mt-0"
            >
                {
                    isMobile ?
                        tipoSorteo == "especial" ?
                        <Header_EspecialPadres height={600} width={MobileWidth}/> :
                        <Header_1 height={600} width={MobileWidth}/> 
                    :  
                        tipoSorteo == "especial" ?
                        <Header_Especial height={550} width={1000}/>
                        : 
                        <Header_1 height={600} width={MobileWidth}/> 
                }
                 
                 
                <img 
                    className="object-fill h-[100%] w-full object-center" 
                    src={isMobile? "https://bingove.com/images/verifica_carton.jpg" : "https://bingove.com/images/banner_verifica.png"} 
                    height={isMobile?MobileHeight:550} 
                    width={isMobile?MobileWidth:1000}
                />  
            </Carousel>
        </>)
    }
    const RedesSociales = ()=>{
        const iconSize = isMobile ? "35" : "20"
        return (<>
            <div 
                className="pt-[20px] flex flex-wrap font-bold text-[18px] justify-center items-center gap-x-[50px] 
                gap-y-[15px] lg:gap-[50px] min-h-[50px] w-[100%] bg-[linear-gradient(0deg,#000 0%, #1a1a1a 100%)]  "
                >
                <a 
                    className="no-underline gap-[10px] text-[white] flex justify-center items-center" 
                    href="https://www.instagram.com/bingo_ve1.0/" 
                
                > 
                    <img src={"https://bingove.com/images/instagram_white.png"} height={iconSize} width={iconSize} /> 
                    { 
                        !isMobile &&
                        <span>bingo_ve1.0</span>
                    }               
                </a>

                <a 
                    className="no-underline gap-[10px] text-[white] flex justify-center items-center"
                    href="https://www.facebook.com/bingove1.0" >
                    <img src={"https://bingove.com/images/facebook_white.png"} height={iconSize} width={iconSize} />  
                    { 
                        !isMobile &&
                        <span>bingo_ve1.0</span>
                    } 
                </a>

                <a 
                    className="no-underline gap-[10px] text-[white] flex justify-center items-center"
                    href="https://www.youtube.com/@bingo_ve" >
                   <img src={"https://bingove.com/images/youtube_white.png"} height={iconSize} width={iconSize} />  
                    { 
                        !isMobile &&
                        <span>@bingo_ve</span>
                    } 
                </a>
            </div>
        </>)
    }
    const CustomButtom = ({url,icon,title1,title2})=>{
        const iconSize = isMobile ? "40" : "50"
        return (<>
        
            <a 
                href={url} 
                className=" no-underline flex-col text-center lg:text-left lg:flex-row gap-[10px] text-[white] flex justify-center items-center bg-[#333]
                            p-[5px] pl-[20px] pr-[20px] rounded-[10px] cursor-pointer" 
                > 
                <img src={"https://bingove.com/images/"+icon+".png"} height={iconSize}  width={iconSize} />                
                <span className="">{title1} <br /> {title2}</span>
            </a>
        
        </>)
    }
    const HomeButtons = ()=>{
        return (<>
            <div 
                className=" h-[auto]  lg:h-[50px] flex w-full justify-center items-center gap-[10px]
                    font-bold text-[18px] mt-[30px] mb-[30px] flex-wrap lg:gap-[50px]"
                >
                <CustomButtom title1={"Cartones"} title2={isMobile?"":"Disponibles"} url={"/cartones"} icon={"bola_cartones"}/>
                <CustomButtom title1={"Encuentra"} title2={isMobile?"":"un promotor"} url={"/promotores"} icon={"bola_promotor"}/>
                <CustomButtom title1={"Ver sorteo"} title2={isMobile?"":"en vivo"} url={"/sorteo"} icon={"bola_sorteo"}/>                
                {
                    promotorEmpleo &&
                    <CustomButtom title1={isMobile?"Empleo":"Trabaja con"} title2={isMobile?"":"nosotros"} url={"/empleo"} icon={"bola_trabaja"}/>
                }
                <CustomButtom title1={"Preguntas"} title2={isMobile?"":"frecuentaes"} url={"/preguntas"} icon={"bola_preguntas"}/> 
                
            </div>
        </>)
    }
    const VideoYotube = ({title, url,color})=>{
        let width_video = isMobile? "320" : "560"
        let height_video = isMobile? "380" : "340"
        return (
            <div  
                className="border-solid border-[4px] border-[#9900cc] mt-[24px] 
                    h-[240px] lg:h-[340px] w-[320px lg:w-[560px] text-[white] flex flex-col justify-start 
                    items-center font-bold text-[24px]" >
                {
                title && 
                <span className="absolute -mt-[50px]" style={{color: color || "white"}}>
                    {title}
                </span>
                }
                <Iframe 
                    src= {"https://www.youtube.com/embed/"+url}
                    width={width_video}
                    height={height_video}
                    title={"YouTube-" + title }
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen               
                />
            </div>
        
        );
    } 
    const Informativos = () =>{
        return(<div className="flex w-full lg:p-10 flex-col gap-5 lg:flex-row lg:gap-5 justify-center items-center">
            <VideoYotube title={videoHome1?.valor} url={videoHome1?.extra} />
            <VideoYotube title={videoHome2?.valor} url={videoHome2?.extra} />
        </div>)
    }
    const SelectorSorteos = () =>{
        return (<>
            <select 
                name="" 
                id="" 
                className=" w-full lg:w-[570px] p-[5px] font-[20px] font-[600]"
                >
                <option value=""> SORTEO #38 - s003830122023 - 2 Rondas</option>
                <option value=""> SORTEO #37 - s003730122023 - 2 Rondas</option>
                <option value=""> SORTEO #36 - s003630122023 - 2 Rondas</option>
                <option value=""> SORTEO #35 - s003530122023 - 2 Rondas</option>
            </select>
        
        </>)
    }
    const Espaciador = ({height})=>{
        return(<div className="w-full" style={{minHeight:height}}>

            </div>
        )
    }
    const GanadoresUltimo = () =>{
        return (<div style={
                    {
                        justifyContent:"flex_start",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        marginBottom:20
                    }}>
           <Espaciador height={50} />
            <VideoYotube title={videoUltimo?.valor} url={videoUltimo?.extra}  color='#ffcc00' />
            <Espaciador height={20} />
            
        </div> )
    } 
    const CartonNew = ({showPaid,footerName}) => {
        const numeros = [[3,7,5,4,13],[26,28,30,29,18],[43,37,0,31,40],[56,58,46,49,55],[72,67,65,70,74]]
        const pagado = 1
        const status = "activo"
        const nro = "0023"
        const sorteo = "s003822012024"
        const propietario = "Suheydde Magdaleno"
        
        return (<>
            <div style={
                {
                    background:"url('images/fondo_carton.png')",
                    width:"200px",
                    height:"320px",
                    backgroundSize:"100%",
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"flex-start",
                    alignItems:"center",
                    padding:10,
                    gap:5
                }}>
                <div>
                    <img src={"https://bingove.com/images/logo_bingove_01.png"} height={80} />                
                </div>
                <div style={
                    {
                        width:"180px",
                        height:"20px",
                        background:"green",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        fontWeight:"600"
                    }}>
                         <span>CARTON {nro}</span>
                </div>
                <div style={
                    {
                        width:"180px",
                        height:"20px",
                        background:"#fcefd1",
                        color:"black",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        fontWeight:"600"
                    }}>
                        <span>{footerName ? sorteo : propietario}</span>
                       
                </div>
                <div style={
                    {
                        width:"180px",
                        height:"20px",
                       
                        color:"black",
                        display:"grid",
                        alignItems:"center",
                        justifyContent:"center",
                        fontWeight:"600",
                        gridTemplateColumns:"36px 36px 36px 36px 36px",
                        gridTemplateRows:"36px 36px 36px 36px 36px",

                    }}>

                       {
                        numeros.map((linea)=>{

                            return linea.map((numero,index) =>{

                                if(numero == 0){
                                    return (<span style={
                                        {
                                            background:"#ff0000",
                                            height:"33px",
                                            width:"33px",
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center",
                                            fontSize:10,
                                            color:"white"
                                        }}>
                                        LIBRE
                                    </span>)
                                }
                                return (<>
                                    <span style={
                                        {
                                            background:"#fcefd1",
                                            height:"33px",
                                            width:"33px",
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}>
                                        {numero}
                                    </span>
                                </>)
                            })

                        })
                       }
                       
                </div>
                <div>
                
                </div>                  
            </div>
            {
                footerName &&             
                <div style={
                        {
                            width:"220px",
                            height:"30px",
                            background:"black",
                            color:"white",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            fontWeight:"600"
                        }}>
                            <span>{propietario}</span>
                        
                </div>
            }
        </>)
    }
    const Sorteos = ({sorteo}) =>{

        const rondas = ["Ronda 1","Ronda 2"]
        const bolitas = [74,65,63,33,13,73,71,28,39,72,21,59,18,24,51,8,56,41,32,15,53,17,52,61,38,40,14,10,30,46,11,57,2,60,66,75,54,42,5,1,37,62,12,6,27,50,47,7,48,29,69,36,35,67,25,45,34]
        const Juegos = ["Esquinas","Diagonal","Diagonal Invertido","Cruz","Linea Horizontal","Linea Vertical"]
        return (<>

        <div>
            <hr style={{borderColor:"#fff",width:"700px"}}/>
            <h2 style={{color:"#fff",fontSize:26}}>
                <b>RESULTADOS DE LOS SORTEOS</b>                
            </h2>
            <Espaciador height={20} />
            <SelectorSorteos />
            <Espaciador height={50} />
        </div>

        <div style={
            {
                width:"100%",
                height:"auto",
                display:"flex",
                justifyContent:"center",
                color:"white"
            }}>

            <div style={{width:"80%",height:"auto",minHeight:"30px",background:"#8932ab"}}>
            <Tabs>
                <TabList>
                    {rondas.map((ronda)=>{
                        return <Tab><b>{ronda}</b> </Tab>
                    })}
                </TabList>
                {rondas.map((ronda,index)=>{
                    return (<TabPanel>
                        <div style={{padding:50,display:"flex",flexDirection:"column",alignItems:"center"}} key={"ronda-index-"+index} >
                            <h2>Bolitas en orden de salida</h2>
                            <div style={{flexWrap:"wrap",gap:"5px", display:"flex",padding:20}}>
                                {bolitas.map((bola,index)=>{
                                    let bolaBG = "url('images/bola_gris.png')"
                                    if(index == bolitas.length - 1 ){
                                        bolaBG = "url('images/bola_verde.png')"
                                    }
                                    return (<>
                                    <div style={
                                        {
                                            background:bolaBG,
                                            backgroundSize:"100%",
                                            height:40,
                                            width:40,
                                            color:"#000",
                                            fontWeight:"bold",
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}>
                                        <span>{bola}</span>
                                    </div>
                                    </>
                                    )
                                    //key={"ronda-index-"+index} 
                                })}
                            </div>
                            <div style={
                                {
                                    display:"flex",
                                    flexDirection:"column",
                                    width:"100%",
                                    borderLeft:"solid 1px white",
                                    borderRight:"solid 1px white",
                                    borderBottom:"solid 1px white",
                                    borderRadius:22,
                                    alignItems:"center",
                                    paddingBottom:10,
                                    minHeight:200
                                }}>
                                <div style={
                                    {
                                        border:"solid 1px white", 
                                        fontSize:20,
                                        color:"#ffcc00",
                                        background:"green",
                                        width:"100%",
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        paddingTop:10,
                                        paddingBottom:10,
                                        borderRadius:20,
                                        textTransform:"uppercase"
                                    }}>
                                    <span> <b>CARTON LLENO {ronda}</b> </span>
                                </div>
                                <div style={{height:"auto",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
                                    <Espaciador height={20} />
                                    <CartonNew />
                                    <Espaciador height={20} />
                                    <span><b>{sorteo}</b> </span>
                                </div>
                            </div>
                            <Espaciador height={20} />
                            <span style={
                                {
                                    fontSize:20,
                                    color:"#ffcc00",
                                    textTransform:"uppercase"
                                }}> 
                                <b>OTROS PREMIOS DE LA {ronda}</b> 
                            </span>
                            <Espaciador height={20} />
                            <div style={
                                {
                                    borderRadius:10,
                                    padding:10
                                }}>
                                <Tabs>
                                    <TabList>
                                        {Juegos.map((item)=>{
                                            return (<Tab><b>{item}</b> </Tab>)
                                        })}
                                    </TabList>
                                    
                                        {Juegos.map((item)=>{
                                            //contenido de las rondas
                                            return (<TabPanel>
                                                <div style={
                                                        {
                                                            display:"flex",
                                                            flexDirection:"column",
                                                            width:"100%",
                                                            borderLeft:"solid 1px white",
                                                            borderRight:"solid 1px white",
                                                            borderBottom:"solid 1px white",
                                                            borderRadius:22,
                                                            alignItems:"center",
                                                            paddingBottom:10,
                                                            minHeight:200
                                                        }}>
                                                        
                                                        <div style={{height:"auto",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
                                                            <Espaciador height={20} />
                                                            <CartonNew />
                                                            <Espaciador height={20} />
                                                            <span><b>{sorteo}</b> </span>
                                                        </div>
                                                    </div>
                                                
                                                </TabPanel>)
                                        })}
                                
                                </Tabs>
                            </div>
                        </div>
                    </TabPanel>)
                })}
            </Tabs>

            </div>
        </div>
        </>)
    } 
    const handlerGoToWhatsapp = () => { 
        console.log('redireccion')
        window.location.href = 'https://bingove.com/info'
        
    }


    const WhatsappButton = () => {

        return (<>
        
        <button 
            className="animate-pulse bg-[green] text-white font-[600] py-2 px-2 flex justify-center items-center text-center text-3xl"
             onClick={handlerGoToWhatsapp}
            >
            <img className=" " src="/images/WhatsApp.webp" width={80} />
            <span className="w-[70%]  animate-bounce"> 
                COMPRA TU CARTON AQUI
            </span>
        </button>
        </>)
    }
    const Flotante = ({name,icon,position,action}) => { 
        const handlerAction = () =>{
            if(action){
                console.log('accion',name)
                action()
            }
        }
        return (<> 
            <button 
                className={`fixed z-[10000] hover:w-[90px] w-[80px] h-[80px]  py-2 px-2 flex justify-center items-center  ${position}`}
                onClick={handlerAction} 
                >
                <img src={`/images/${icon}`} width={80} height={80} /> 
            </button>
        </>)
    }

    const handlerWhatsapp = () => {
        let linkTemporal = ''
        let numero = ''
        if(whatsappMode=='fixed'){ 
            numero = whatsappList[0]
        } else if (whatsappMode=='random') {
            //randomize 
        }  
        console.log('whatsappMode',whatsappMode)
        console.log('whatsappList[0]',whatsappList[0])
        linkTemporal = `${whatsappLink}${numero}`
        window.open(linkTemporal,'_blank')
    }

    return (
        <div className="pages-home flex flex-col gap-[1] pb-[0px]">
            <Header noLogin={true}  empleo={promotorEmpleo} />
            
            <CarrouselJs   />
            <WhatsappButton />
            <RedesSociales />
            <HomeButtons />
            <Informativos />
            <GanadoresUltimo />
            { 
                historial && 
                <Sorteos sorteo="s003822012024"/>
            }
            <Flotante 
                name={'Telegram'}
                icon={"Telegram.webp"} 
                position={'bottom-[80px] right-[10px]'} 
                action={()=>{  
                    window.open(telegramLink,'_blank')
                }}
            />
            <Flotante 
                name={'Whatsapp'}
                icon={"WhatsApp.webp"} 
                position={'bottom-[10px] right-[10px]'} 
                action={handlerWhatsapp}
            />
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Home;

  /*  
  <Footer noLogin={true} />
 <div className="home-section-1 home-section">
                <div className="premios">
                    <span>500$</span>
                </div>
                <div className="carrousel">
                    <Link key={'link005'} className="" to="/sorteo"  >
                        <img src="images/sorteo-14-05-2023.jpg" height={400} alt="" />
                    </Link>
                    <img src="images/verificar-carton.jpg" height={400} alt="" />
                    <img src="images/banner-1.jpg" height={400} alt="" />
                </div>
            </div>
  */

            //https://api.whatsapp.com/send/?text=Hola+%2C+Quisiera+saber+mas+de+BingoVe.&type=phone_number&phone=584124431815